.page-header {
  background-color: #80bbd1;
  padding: 1em;
}

.page-header-shrink {
  padding: 0.1em;
}

.page-header-title {
  text-align: center;
  font-family: "Times New Roman", Times, serif;
  font-variant: small-caps;
  letter-spacing: 0.1em;
}

.dark-bg {
  background-color: #282c34;
}

.button-bar {
  justify-content: center !important;
  padding: 1em;
}

.header-menu-button {
  padding-right: 2rem !important;
}

.header-divider {
  margin: 5px 0px 0px 0px;
}

.calculation-card-container {
  max-width: 1200px !important;
}

.calculation-card {
  background-color: #80bbd1 !important;
  color: #000000;
}

.calculation-card-element {
  background-color: #80bbd1 !important;
  color: #000000;
}

.calculation-card-footer {
  text-align: right;
  padding: 0.25rem 1.25rem 0.25rem 0.25rem !important;
}

.my-modal-header {
  background-color: #7cb5ca !important;
  color: #000000;
  border-bottom: 1px solid #6d9eb1 !important;
}

.my-modal-footer {
  background-color: #80bbd1 !important;
  color: #000000;
  border-top: 1px solid #6d9eb1 !important;
}

.red-text {
  color: red !important;
}

.table-cell {
  text-align: center;
  min-width: 12ch;
  border-color: rgba(155, 155, 155, 0.3) !important;
}

.disabled-cell-input-element {
  background-color: #89a0b5 !important;
}

.read-only-cell-input-element {
  background-color: #b9d4df !important;
  padding-left: .25rem !important;
  padding-right: .25rem !important;
  min-width: 6ch;
  text-align: center;
}

.cell-input-element {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
  min-width: 6ch;
  text-align: center;
}

.table-cell-slim {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.action-icon {
  height: 1.5ch;
}

.my-dropdown {
  background-color: #80bbd1 !important;
  color: #000000 !important;
}

.my-dropdown:hover {
 background-color: #007bff !important;
 color: #FFFFFF !important;
}

.dropdown-menu {
  background-color: #80bbd1 !important;
}

.name-badge-delete {
  cursor: pointer;
}

.name-badge-delete:hover {
  background-color: #ad073c;
}

.slim-button {
  padding: 0.1rem 0.25rem 0.1rem 0.25rem !important;
}

.react-toggle {
  vertical-align: middle;
  margin-left: 4px;
}

.custom-toggle.react-toggle--checked .react-toggle-track {
  background-color: #007bff;
}

.custom-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color:#0069d9;
}

.text-right {
  text-align: right;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.responsive {
  width: 100%;
  height: auto;
}

.img-w-493 {
  max-width: 493px !important;
}

.monospace {
  font-family: monospace;
  font-size: smaller;
}

.modal-90w {
  width: 95% !important;
  max-width: 1200px !important;
}

p.darker {
  background-color: #7cb5ca;
}

.darker-link-button {
  color: #003bff !important;
  text-align: left !important;
}

.email {
  color: #282c34;
  font-weight: bolder;
  letter-spacing: 0.07em;
}
